export const getImprovements = `query improvements($query: ImprovementQueryInput, $limit: Int, $sortBy: ImprovementSortByInput){
  improvements(query: $query, limit: $limit, sortBy: $sortBy) {
      _id
      note
      description
      dueDate
      assignee {
        _id
        name
        email
        locations {
          _id
          location
        }
      }
      tags
      deleted
      origin
      origin_id
      completed
      important
      imageKey
      metadata {
        name
        type
        answer
      }
      associatedCases {
        _id
      }
  }
}`

export const getImprovement = `query improvement($query: ImprovementQueryInput){
  improvement(query: $query) {
      _id
      note
      description
      dueDate
      stage
      assignee {
        _id
        name
        email
      }
      completed
      imageKey
      associatedCases {
        _id
      }
      areas {
        _id
      }
      proposalResponsible {
        _id
        name
        email
      }
      approvalResponsible {
        _id
        name
        email
      }
      verificationResponsible {
        _id
        name
        email
      }
      proposalDueDate
      approvalDueDate
      verificationDueDate
      approvalComment
      verificationComment
      problemStatement
      why1
      why2
      why3
      why4
      why5
      rootCause
      isCompletedOverdue
      actionPlans {
        _id
        type
        startDate
        endDate
        name
        description
        evidence_key
        executionDate
        stage
        approvalResult
        approvalComment
        correctiveResult
        correctiveComment
        verificationResult
        verificationComment
        executionResponsible {
          _id
          name
        }
        originIndex
        isRootCause
      }
  }
}`

export const getImprovementNotes = `query improvements($query: ImprovementQueryInput, $limit: Int, $sortBy: ImprovementSortByInput){
  improvements(query: $query, limit: $limit, sortBy: $sortBy) {
    _id
    note
    activity
    behaviour {
      name
      type
      answer
    }
  }
}`

export const addImprovement = `mutation insertOneImprovement($data: ImprovementInsertInput!){
  insertOneImprovement(data: $data) {
    _id
  }
}`

export const updateImprovement = `mutation updateOneImprovement($query: ImprovementQueryInput!, $data: ImprovementUpdateInput!) {
  updateOneImprovement(query: $query, set: $data) {
    _id
  }
}`